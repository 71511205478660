/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as r } from "../chunks/tslib.es6.js";
import { clone as t } from "../core/lang.js";
import { toPt as e } from "../core/screenUtils.js";
import { property as o } from "../core/accessorSupport/decorators/property.js";
import "../core/has.js";
import "../core/Logger.js";
import { enumeration as s } from "../core/accessorSupport/decorators/enumeration.js";
import { reader as i } from "../core/accessorSupport/decorators/reader.js";
import { subclass as p } from "../core/accessorSupport/decorators/subclass.js";
import h from "./MarkerSymbol.js";
import { urlPropertyDefinition as u, sourcePropertyDefinition as c } from "./support/urlUtils.js";
var l;
let a = l = class extends h {
  constructor(...r) {
    super(...r), this.color = null, this.type = "picture-marker", this.url = null, this.source = null, this.height = 12, this.width = 12, this.size = null;
  }
  normalizeCtorArgs(r, t, o) {
    if (r && "string" != typeof r && null == r.imageData) return r;
    const s = {};
    return r && (s.url = r), null != t && (s.width = e(t)), null != o && (s.height = e(o)), s;
  }
  readHeight(r, t) {
    return t.size || r;
  }
  readWidth(r, t) {
    return t.size || r;
  }
  clone() {
    const r = new l({
      angle: this.angle,
      height: this.height,
      url: this.url,
      width: this.width,
      xoffset: this.xoffset,
      yoffset: this.yoffset
    });
    return r._set("source", t(this.source)), r;
  }
  hash() {
    return `${super.hash()}.${this.height}.${this.url}.${this.width}`;
  }
};
r([o({
  json: {
    write: !1
  }
})], a.prototype, "color", void 0), r([s({
  esriPMS: "picture-marker"
}, {
  readOnly: !0
})], a.prototype, "type", void 0), r([o(u)], a.prototype, "url", void 0), r([o(c)], a.prototype, "source", void 0), r([o({
  type: Number,
  cast: e,
  json: {
    write: !0
  }
})], a.prototype, "height", void 0), r([i("height", ["height", "size"])], a.prototype, "readHeight", null), r([o({
  type: Number,
  cast: e,
  json: {
    write: !0
  }
})], a.prototype, "width", void 0), r([o({
  json: {
    write: !1
  }
})], a.prototype, "size", void 0), a = l = r([p("esri.symbols.PictureMarkerSymbol")], a);
const n = a;
export { n as default };