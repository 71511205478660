/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as t } from "../chunks/tslib.es6.js";
import { px2pt as r } from "../core/screenUtils.js";
import { property as e } from "../core/accessorSupport/decorators/property.js";
import "../core/has.js";
import "../core/Logger.js";
import "../core/RandomLCG.js";
import { enumeration as o } from "../core/accessorSupport/decorators/enumeration.js";
import { subclass as s } from "../core/accessorSupport/decorators/subclass.js";
import i from "./LineStyleMarker3D.js";
import l from "./Symbol3DLayer.js";
import p from "./patterns/LineStylePattern3D.js";
import { symbol3dLinePatternProperty as a } from "./patterns/utils.js";
import { white as n } from "./support/colors.js";
import { screenSizeProperty as m } from "./support/materialUtils.js";
import { Symbol3DMaterial as c } from "./support/Symbol3DMaterial.js";
import { lineJoins as y, lineCaps as u } from "./support/symbolLayerUtils3D.js";
var j;
let d = j = class extends l {
  constructor(t) {
    super(t), this.material = null, this.type = "line", this.join = "miter", this.cap = "butt", this.size = r(1), this.pattern = null, this.marker = null;
  }
  clone() {
    const t = {
      enabled: this.enabled,
      material: null != this.material ? this.material.clone() : null,
      size: this.size,
      join: this.join,
      cap: this.cap,
      pattern: null != this.pattern ? this.pattern.clone() : null,
      marker: null != this.marker ? this.marker.clone() : null
    };
    return new j(t);
  }
  static fromSimpleLineSymbol(t) {
    const e = {
      enabled: !0,
      size: t.width ?? r(1),
      cap: t.cap || "butt",
      join: t.join || "miter",
      pattern: t.style ? new p({
        style: t.style
      }) : null,
      material: new c({
        color: (t.color || n).clone()
      }),
      marker: t.marker ? new i({
        placement: t.marker.placement,
        style: t.marker.style,
        color: t.marker.color?.clone() ?? null
      }) : null
    };
    return new j(e);
  }
};
t([e({
  type: c,
  json: {
    write: !0
  }
})], d.prototype, "material", void 0), t([o({
  Line: "line"
}, {
  readOnly: !0
})], d.prototype, "type", void 0), t([e({
  type: y,
  json: {
    write: !0,
    default: "miter"
  }
})], d.prototype, "join", void 0), t([e({
  type: u,
  json: {
    write: !0,
    default: "butt"
  }
})], d.prototype, "cap", void 0), t([e(m)], d.prototype, "size", void 0), t([e(a)], d.prototype, "pattern", void 0), t([e({
  types: {
    key: "type",
    base: i,
    typeMap: {
      style: i
    }
  },
  json: {
    write: !0
  }
})], d.prototype, "marker", void 0), d = j = t([s("esri.symbols.LineSymbol3DLayer")], d);
const h = d;
export { h as default };