/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as o } from "../../chunks/tslib.es6.js";
import r from "../../core/Accessor.js";
import { property as s } from "../../core/accessorSupport/decorators/property.js";
import "../../core/has.js";
import "../../core/Logger.js";
import "../../core/RandomLCG.js";
import { subclass as t } from "../../core/accessorSupport/decorators/subclass.js";
var e;
let p = e = class extends r {
  constructor() {
    super(...arguments), this.x = 0, this.y = 0;
  }
  clone() {
    return new e({
      x: this.x,
      y: this.y
    });
  }
};
o([s({
  type: Number
})], p.prototype, "x", void 0), o([s({
  type: Number
})], p.prototype, "y", void 0), p = e = o([t("esri.symbols.support.Symbol3DAnchorPosition2D")], p);
export { p as Symbol3DAnchorPosition2D };