/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { makeData as e, isAbsolute as r, join as t, isDataProtocol as a, dataComponents as n } from "../../core/urlUtils.js";
import { f as i, w as u } from "../../chunks/persistableUrlUtils.js";
function l(r, t, a) {
  return t.imageData ? e({
    mediaType: t.contentType || "image/png",
    isBase64: !0,
    data: t.imageData
  }) : o(t.url, a);
}
function o(e, a) {
  if (!r(e)) {
    const r = p(a);
    if (r) return t(r, "images", e);
  }
  return i(e, a);
}
function s(e, r, t, i) {
  if (a(e)) {
    const a = n(e);
    if (!a) return;
    r.contentType = a.mediaType, r.imageData = a.data, t && t.imageData === r.imageData && t.url && u(t.url, r, "url", i);
  } else u(e, r, "url", i);
}
const m = {
    json: {
      read: {
        source: ["imageData", "url"],
        reader: l
      },
      write: {
        writer(e, r, t, a) {
          s(e, r, this.source, a);
        }
      }
    }
  },
  c = {
    readOnly: !0,
    json: {
      read: {
        source: ["imageData", "url"],
        reader(e, r, t) {
          const a = {};
          return r.imageData && (a.imageData = r.imageData), r.contentType && (a.contentType = r.contentType), r.url && (a.url = o(r.url, t)), a;
        }
      }
    }
  };
function p(e) {
  if (!e) return null;
  const {
    origin: r,
    layer: t
  } = e;
  if ("service" !== r && "portal-item" !== r) return null;
  const a = t?.type;
  return "feature" === a || "stream" === a ? t.parsedUrl?.path : "map-image" === a || "tile" === a ? e.url?.path : null;
}
export { o as read, l as readImageDataOrUrl, c as sourcePropertyDefinition, m as urlPropertyDefinition, s as writeImageDataAndUrl };