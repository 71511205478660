/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as t } from "../../chunks/tslib.es6.js";
import o from "../../Color.js";
import { JSONSupport as r } from "../../core/JSONSupport.js";
import { px2pt as s } from "../../core/screenUtils.js";
import { property as e } from "../../core/accessorSupport/decorators/property.js";
import "../../core/has.js";
import "../../core/Logger.js";
import "../../core/RandomLCG.js";
import { subclass as p } from "../../core/accessorSupport/decorators/subclass.js";
import { symbol3dLinePatternProperty as i } from "../patterns/utils.js";
import { colorAndTransparencyProperty as l, screenSizeProperty as n } from "./materialUtils.js";
import { lineCaps as a } from "./symbolLayerUtils3D.js";
var c;
let m = c = class extends r {
  constructor(t) {
    super(t), this.color = new o([0, 0, 0, 1]), this.size = s(1), this.pattern = null, this.patternCap = "butt";
  }
  clone() {
    const t = {
      color: null != this.color ? this.color.clone() : null,
      size: this.size,
      pattern: null != this.pattern ? this.pattern.clone() : null,
      patternCap: this.patternCap
    };
    return new c(t);
  }
};
t([e(l)], m.prototype, "color", void 0), t([e(n)], m.prototype, "size", void 0), t([e(i)], m.prototype, "pattern", void 0), t([e({
  type: a,
  json: {
    default: "butt",
    write: {
      overridePolicy() {
        return {
          enabled: null != this.pattern
        };
      }
    }
  }
})], m.prototype, "patternCap", void 0), m = c = t([p("esri.symbols.support.Symbol3DOutline")], m);
export { m as Symbol3DOutline };