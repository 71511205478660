/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as t } from "../chunks/tslib.es6.js";
import { clone as o } from "../core/lang.js";
import { property as e } from "../core/accessorSupport/decorators/property.js";
import "../core/has.js";
import "../core/Logger.js";
import { enumeration as r } from "../core/accessorSupport/decorators/enumeration.js";
import { subclass as i } from "../core/accessorSupport/decorators/subclass.js";
import { writer as n } from "../core/accessorSupport/decorators/writer.js";
import l from "./Font.js";
import s from "./Symbol3DLayer.js";
import { screenSizeProperty as a } from "./support/materialUtils.js";
import { Symbol3DHalo as p } from "./support/Symbol3DHalo.js";
import { Symbol3DMaterial as u } from "./support/Symbol3DMaterial.js";
import { Symbol3DTextBackground as h } from "./support/Symbol3DTextBackground.js";
import { horizontalAlignmentProperty as m, lineHeightProperty as c, verticalAlignmentProperty as f } from "./support/textUtils.js";
var g;
let y = g = class extends s {
  constructor(t) {
    super(t), this._userSize = void 0, this.halo = null, this.horizontalAlignment = "center", this.lineHeight = 1, this.material = null, this.background = null, this.text = null, this.type = "text", this.verticalAlignment = "baseline";
  }
  get font() {
    return this._get("font") || null;
  }
  set font(t) {
    null != t && null != this._userSize && (t.size = this._userSize), this._set("font", t);
  }
  writeFont(t, o, e, r) {
    const i = {
      ...r,
      textSymbol3D: !0
    };
    o.font = t.write({}, i), delete o.font.size;
  }
  get size() {
    return null != this._userSize ? this._userSize : null != this.font?.size ? this.font.size : 9;
  }
  set size(t) {
    this._userSize = t, null != this.font && (this.font.size = this._userSize), this.notifyChange("size");
  }
  clone() {
    const t = new g({
      enabled: this.enabled,
      font: this.font && o(this.font),
      halo: this.halo && o(this.halo),
      horizontalAlignment: this.horizontalAlignment,
      lineHeight: this.lineHeight,
      material: null != this.material ? this.material.clone() : null,
      text: this.text,
      verticalAlignment: this.verticalAlignment,
      background: o(this.background)
    });
    return t._userSize = this._userSize, t;
  }
  static fromTextSymbol(t) {
    return new g({
      font: null != t.font ? t.font.clone() : new l(),
      halo: d(t.haloColor, t.haloSize),
      horizontalAlignment: t.horizontalAlignment,
      lineHeight: t.lineHeight,
      material: t.color ? new u({
        color: t.color.clone()
      }) : null,
      text: t.text,
      verticalAlignment: t.verticalAlignment,
      background: t.backgroundColor ? new h({
        color: t.backgroundColor.clone()
      }) : null
    });
  }
};
function d(t, e) {
  return t && null != e && e > 0 ? new p({
    color: o(t),
    size: e
  }) : null;
}
t([e({
  type: l,
  json: {
    write: !0
  }
})], y.prototype, "font", null), t([n("font")], y.prototype, "writeFont", null), t([e({
  type: p,
  json: {
    write: !0
  }
})], y.prototype, "halo", void 0), t([e({
  ...m,
  json: {
    default: "center",
    write: !0
  }
})], y.prototype, "horizontalAlignment", void 0), t([e({
  ...c,
  json: {
    default: 1,
    write: !0
  }
})], y.prototype, "lineHeight", void 0), t([e({
  type: u,
  json: {
    write: !0
  }
})], y.prototype, "material", void 0), t([e({
  type: h,
  json: {
    write: !0
  }
})], y.prototype, "background", void 0), t([e(a)], y.prototype, "size", null), t([e({
  type: String,
  json: {
    write: !0
  }
})], y.prototype, "text", void 0), t([r({
  Text: "text"
}, {
  readOnly: !0
})], y.prototype, "type", void 0), t([e({
  ...f,
  json: {
    default: "baseline",
    write: !0
  }
})], y.prototype, "verticalAlignment", void 0), y = g = t([i("esri.symbols.TextSymbol3DLayer")], y);
const z = y;
export { z as default };