/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as e } from "../chunks/tslib.es6.js";
import { property as o } from "../core/accessorSupport/decorators/property.js";
import "../core/has.js";
import "../core/Logger.js";
import "../core/RandomLCG.js";
import { enumeration as t } from "../core/accessorSupport/decorators/enumeration.js";
import { subclass as r } from "../core/accessorSupport/decorators/subclass.js";
import s from "./Symbol3DLayer.js";
import { symbol3dEdgesProperty as a } from "./edges/utils.js";
import { Symbol3DMaterial as i } from "./support/Symbol3DMaterial.js";
var p;
let l = p = class extends s {
  constructor(e) {
    super(e), this.type = "extrude", this.size = 1, this.material = null, this.castShadows = !0, this.edges = null;
  }
  clone() {
    return new p({
      edges: this.edges?.clone(),
      enabled: this.enabled,
      material: this.material?.clone(),
      castShadows: this.castShadows,
      size: this.size
    });
  }
};
e([t({
  Extrude: "extrude"
}, {
  readOnly: !0
})], l.prototype, "type", void 0), e([o({
  type: Number,
  json: {
    write: {
      enabled: !0,
      isRequired: !0
    }
  },
  nonNullable: !0
})], l.prototype, "size", void 0), e([o({
  type: i,
  json: {
    write: !0
  }
})], l.prototype, "material", void 0), e([o({
  type: Boolean,
  nonNullable: !0,
  json: {
    write: !0,
    default: !0
  }
})], l.prototype, "castShadows", void 0), e([o(a)], l.prototype, "edges", void 0), l = p = e([r("esri.symbols.ExtrudeSymbol3DLayer")], l);
const d = l;
export { d as default };