/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as o } from "../chunks/tslib.es6.js";
import { clone as t } from "../core/lang.js";
import r from "../core/Logger.js";
import { makeData as e } from "../core/urlUtils.js";
import { property as i } from "../core/accessorSupport/decorators/property.js";
import "../core/has.js";
import { enumeration as s } from "../core/accessorSupport/decorators/enumeration.js";
import { subclass as n } from "../core/accessorSupport/decorators/subclass.js";
import c from "./Symbol3DLayer.js";
import { white as l, isBlack as a } from "./support/colors.js";
import { IconSymbol3DLayerResource as p } from "./support/IconSymbol3DLayerResource.js";
import { screenSizeProperty as m } from "./support/materialUtils.js";
import { Symbol3DAnchorPosition2D as u } from "./support/Symbol3DAnchorPosition2D.js";
import { Symbol3DIconOutline as h } from "./support/Symbol3DIconOutline.js";
import { Symbol3DMaterial as y } from "./support/Symbol3DMaterial.js";
var f;
const d = "esri.symbols.IconSymbol3DLayer";
let b = f = class extends c {
  constructor(o) {
    super(o), this.material = null, this.resource = null, this.type = "icon", this.size = 12, this.anchor = "center", this.anchorPosition = null, this.outline = null;
  }
  clone() {
    return new f({
      anchor: this.anchor,
      anchorPosition: t(this.anchorPosition),
      enabled: this.enabled,
      material: t(this.material),
      outline: t(this.outline),
      resource: t(this.resource),
      size: this.size
    });
  }
  static fromSimpleMarkerSymbol(o) {
    const t = o.color || l,
      r = g(o),
      e = o.outline && o.outline.width > 0 ? {
        size: o.outline.width,
        color: (o.outline.color || l).clone()
      } : null;
    return new f({
      size: o.size,
      resource: {
        primitive: S(o.style)
      },
      material: {
        color: t
      },
      outline: e,
      anchor: r ? "relative" : void 0,
      anchorPosition: r
    });
  }
  static fromPictureMarkerSymbol(o) {
    const t = !o.color || a(o.color) ? l : o.color,
      r = g(o);
    return new f({
      size: o.width <= o.height ? o.height : o.width,
      resource: {
        href: o.url
      },
      material: {
        color: t.clone()
      },
      anchor: r ? "relative" : void 0,
      anchorPosition: r
    });
  }
  static fromCIMSymbol(o) {
    return new f({
      resource: {
        href: e({
          mediaType: "application/json",
          data: JSON.stringify(o.data)
        })
      }
    });
  }
};
o([i({
  type: y,
  json: {
    write: !0
  }
})], b.prototype, "material", void 0), o([i({
  type: p,
  json: {
    write: !0
  }
})], b.prototype, "resource", void 0), o([s({
  Icon: "icon"
}, {
  readOnly: !0
})], b.prototype, "type", void 0), o([i(m)], b.prototype, "size", void 0), o([s({
  center: "center",
  left: "left",
  right: "right",
  top: "top",
  bottom: "bottom",
  topLeft: "top-left",
  topRight: "top-right",
  bottomLeft: "bottom-left",
  bottomRight: "bottom-right",
  relative: "relative"
}), i({
  json: {
    default: "center"
  }
})], b.prototype, "anchor", void 0), o([i({
  type: u,
  json: {
    type: [Number],
    read: {
      reader: o => new u({
        x: o[0],
        y: o[1]
      })
    },
    write: {
      writer: (o, t) => {
        t.anchorPosition = [o.x, o.y];
      },
      overridePolicy() {
        return {
          enabled: "relative" === this.anchor
        };
      }
    }
  }
})], b.prototype, "anchorPosition", void 0), o([i({
  type: h,
  json: {
    write: !0
  }
})], b.prototype, "outline", void 0), b = f = o([n(d)], b);
const j = b;
function g(o) {
  const t = "width" in o ? o.width : o.size,
    r = "height" in o ? o.height : o.size,
    e = v(o.xoffset),
    i = v(o.yoffset);
  return (e || i) && t && r ? {
    x: -e / t,
    y: i / r
  } : null;
}
function v(o) {
  return isFinite(o) ? o : 0;
}
const w = {
  circle: "circle",
  cross: "cross",
  diamond: "kite",
  square: "square",
  x: "x",
  triangle: "triangle",
  path: null
};
function S(o) {
  const t = w[o];
  return t || (r.getLogger(d).warn(`${o} cannot be mapped to Icon symbol. Fallback to "circle"`), "circle");
}
export { j as default };