/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as e } from "../chunks/tslib.es6.js";
import r from "../core/Collection.js";
import { referenceSetter as t, castForReferenceSetter as o } from "../core/collectionUtils.js";
import s from "../core/Logger.js";
import { isAbsolute as l, normalize as i, hasSamePortal as a } from "../core/urlUtils.js";
import m from "../core/Warning.js";
import { property as y } from "../core/accessorSupport/decorators/property.js";
import { cast as n } from "../core/accessorSupport/decorators/cast.js";
import "../core/RandomLCG.js";
import "../core/has.js";
import { reader as p } from "../core/accessorSupport/decorators/reader.js";
import { subclass as c } from "../core/accessorSupport/decorators/subclass.js";
import { writer as b } from "../core/accessorSupport/decorators/writer.js";
import u from "../portal/Portal.js";
import { f, t as d } from "../chunks/persistableUrlUtils.js";
import g from "./ExtrudeSymbol3DLayer.js";
import j from "./FillSymbol3DLayer.js";
import h from "./IconSymbol3DLayer.js";
import S from "./LineSymbol3DLayer.js";
import L from "./ObjectSymbol3DLayer.js";
import O from "./PathSymbol3DLayer.js";
import _ from "./Symbol.js";
import U from "./Symbol3DLayer.js";
import D from "./TextSymbol3DLayer.js";
import w from "./WaterSymbol3DLayer.js";
import N from "./support/StyleOrigin.js";
import { Thumbnail as x } from "./support/Thumbnail.js";
const v = {
    icon: h,
    object: L,
    line: S,
    path: O,
    fill: j,
    extrude: g,
    text: D,
    water: w
  },
  C = r.ofType({
    base: U,
    key: "type",
    typeMap: v,
    errorContext: "symbol-layer"
  });
let T = class extends _ {
  constructor(e) {
    super(e), this.styleOrigin = null, this.thumbnail = null, this.type = null;
    const t = this.__accessor__ && this.__accessor__.metadata && this.__accessor__.metadata.symbolLayers,
      o = t?.type,
      s = o || r;
    this._set("symbolLayers", new s());
  }
  get color() {
    return null;
  }
  set color(e) {
    this.constructed && s.getLogger(this).error("Symbol3D does not support colors on the symbol level. Colors may be set on individual symbol layer materials instead.");
  }
  set symbolLayers(e) {
    t(e, this._get("symbolLayers"));
  }
  readStyleOrigin(e, r, t) {
    if (e.styleUrl && e.name) {
      const r = f(e.styleUrl, t);
      return new N({
        styleUrl: r,
        name: e.name
      });
    }
    if (e.styleName && e.name) return new N({
      portal: t?.portal || u.getDefault(),
      styleName: e.styleName,
      name: e.name
    });
    t?.messages && t.messages.push(new m("symbol3d:incomplete-style-origin", "Style origin requires either a 'styleUrl' or 'styleName' and a 'name' property", {
      context: t,
      definition: e
    }));
  }
  writeStyleOrigin(e, r, t, o) {
    if (e.styleUrl && e.name) {
      let t = d(e.styleUrl, o);
      l(t) && (t = i(t)), r.styleOrigin = {
        styleUrl: t,
        name: e.name
      };
    } else e.styleName && e.name && (e.portal && o?.portal && !a(e.portal.restUrl, o.portal.restUrl) ? o?.messages && o.messages.push(new m("symbol:cross-portal", "The symbol style origin cannot be persisted because it refers to an item on a different portal than the one being saved to.", {
      symbol: this
    })) : r.styleOrigin = {
      styleName: e.styleName,
      name: e.name
    });
  }
  normalizeCtorArgs(e) {
    return e instanceof U || e && v[e.type] ? {
      symbolLayers: [e]
    } : Array.isArray(e) ? {
      symbolLayers: e
    } : e;
  }
};
e([y({
  json: {
    read: !1,
    write: !1
  }
})], T.prototype, "color", null), e([y({
  type: C,
  nonNullable: !0,
  json: {
    write: !0
  }
}), n(o)], T.prototype, "symbolLayers", null), e([y({
  type: N
})], T.prototype, "styleOrigin", void 0), e([p("styleOrigin")], T.prototype, "readStyleOrigin", null), e([b("styleOrigin", {
  "styleOrigin.styleUrl": {
    type: String
  },
  "styleOrigin.styleName": {
    type: String
  },
  "styleOrigin.name": {
    type: String
  }
})], T.prototype, "writeStyleOrigin", null), e([y({
  type: x,
  json: {
    read: !1
  }
})], T.prototype, "thumbnail", void 0), e([y({
  type: ["point-3d", "line-3d", "polygon-3d", "mesh-3d", "label-3d"],
  readOnly: !0
})], T.prototype, "type", void 0), T = e([c("esri.symbols.Symbol3D")], T);
const k = T;
export { k as default };