/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as r } from "../../chunks/tslib.es6.js";
import o from "../../core/Accessor.js";
import { property as s } from "../../core/accessorSupport/decorators/property.js";
import "../../core/has.js";
import "../../core/Logger.js";
import "../../core/RandomLCG.js";
import { subclass as t } from "../../core/accessorSupport/decorators/subclass.js";
var e;
let c = e = class extends o {
  constructor() {
    super(...arguments), this.url = "";
  }
  clone() {
    return new e({
      url: this.url
    });
  }
};
r([s({
  type: String
})], c.prototype, "url", void 0), c = e = r([t("esri.symbols.support.Thumbnail")], c);
export { c as Thumbnail };