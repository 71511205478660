/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as r } from "../chunks/tslib.es6.js";
import { clone as o } from "../core/lang.js";
import { numericHash as t } from "../core/string.js";
import { property as e } from "../core/accessorSupport/decorators/property.js";
import "../core/has.js";
import "../core/Logger.js";
import { enumeration as s } from "../core/accessorSupport/decorators/enumeration.js";
import { reader as a } from "../core/accessorSupport/decorators/reader.js";
import { subclass as i } from "../core/accessorSupport/decorators/subclass.js";
import { writer as p } from "../core/accessorSupport/decorators/writer.js";
import { collectArcadeFieldNames as c } from "../layers/support/fieldUtils.js";
import m from "./Symbol.js";
var n;
let l = n = class extends m {
  constructor(r) {
    super(r), this.data = null, this.type = "cim";
  }
  readData(r, o) {
    return o;
  }
  writeData(r, o) {
    Object.assign(o, r);
  }
  async collectRequiredFields(r, o) {
    if ("CIMSymbolReference" === this.data?.type) {
      const t = this.data.primitiveOverrides;
      if (t) {
        const e = t.map(t => {
          const e = t.valueExpressionInfo;
          return e ? c(r, o, e.expression) : null;
        });
        await Promise.all(e);
      }
    }
  }
  clone() {
    return new n({
      data: o(this.data)
    });
  }
  hash() {
    return t(JSON.stringify(this.data)).toString();
  }
};
r([e({
  json: {
    write: !1
  }
})], l.prototype, "color", void 0), r([e({
  json: {
    write: !0
  }
})], l.prototype, "data", void 0), r([a("data", ["symbol"])], l.prototype, "readData", null), r([p("data", {})], l.prototype, "writeData", null), r([s({
  CIMSymbolReference: "cim"
}, {
  readOnly: !0
})], l.prototype, "type", void 0), l = n = r([i("esri.symbols.CIMSymbol")], l);
const d = l;
export { d as default };