/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as o } from "../chunks/tslib.es6.js";
import t from "../Color.js";
import r from "../core/Collection.js";
import { clone as e } from "../core/lang.js";
import { property as s } from "../core/accessorSupport/decorators/property.js";
import "../core/has.js";
import "../core/Logger.js";
import { enumeration as l } from "../core/accessorSupport/decorators/enumeration.js";
import { subclass as i } from "../core/accessorSupport/decorators/subclass.js";
import m from "./IconSymbol3DLayer.js";
import a from "./ObjectSymbol3DLayer.js";
import n from "./Symbol3D.js";
import y from "./TextSymbol3DLayer.js";
import { hasVisibleCallout as c, hasVisibleVerticalOffset as p, calloutProperty as f } from "./callouts/calloutUtils.js";
import u from "./callouts/LineCallout3D.js";
import b from "./support/Symbol3DVerticalOffset.js";
var h;
const S = r.ofType({
  base: null,
  key: "type",
  typeMap: {
    icon: m,
    object: a,
    text: y
  }
});
let j = h = class extends n {
  constructor(o) {
    super(o), this.verticalOffset = null, this.callout = null, this.symbolLayers = new S(), this.type = "point-3d";
  }
  supportsCallout() {
    if ((this.symbolLayers ? this.symbolLayers.length : 0) < 1) return !1;
    for (const o of this.symbolLayers.items) switch (o.type) {
      case "icon":
      case "text":
      case "object":
        continue;
      default:
        return !1;
    }
    return !0;
  }
  hasVisibleCallout() {
    return c(this);
  }
  hasVisibleVerticalOffset() {
    return p(this);
  }
  clone() {
    return new h({
      verticalOffset: e(this.verticalOffset),
      callout: e(this.callout),
      styleOrigin: e(this.styleOrigin),
      symbolLayers: e(this.symbolLayers),
      thumbnail: e(this.thumbnail)
    });
  }
  static fromSimpleMarkerSymbol(o) {
    return new h({
      symbolLayers: new r([m.fromSimpleMarkerSymbol(o)])
    });
  }
  static fromPictureMarkerSymbol(o) {
    return new h({
      symbolLayers: new r([m.fromPictureMarkerSymbol(o)])
    });
  }
  static fromCIMSymbol(o) {
    const e = o.data?.symbol?.type;
    if ("CIMPointSymbol" !== e) return null;
    const s = o.data.symbol;
    return new h(s?.callout ? {
      symbolLayers: new r([m.fromCIMSymbol(o)]),
      callout: new u({
        size: .5,
        color: new t([0, 0, 0])
      }),
      verticalOffset: new b({
        screenLength: 40
      })
    } : {
      symbolLayers: new r([m.fromCIMSymbol(o)])
    });
  }
  static fromTextSymbol(o) {
    return new h({
      symbolLayers: new r([y.fromTextSymbol(o)])
    });
  }
};
o([s({
  type: b,
  json: {
    write: !0
  }
})], j.prototype, "verticalOffset", void 0), o([s(f)], j.prototype, "callout", void 0), o([s({
  type: S,
  json: {
    origins: {
      "web-scene": {
        write: !0
      }
    }
  }
})], j.prototype, "symbolLayers", void 0), o([l({
  PointSymbol3D: "point-3d"
}, {
  readOnly: !0
})], j.prototype, "type", void 0), j = h = o([i("esri.symbols.PointSymbol3D")], j);
const w = j;
export { w as default };