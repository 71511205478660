/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as o } from "../../chunks/tslib.es6.js";
import r from "../../Color.js";
import { clone as t } from "../../core/lang.js";
import { px2pt as s } from "../../core/screenUtils.js";
import { property as e } from "../../core/accessorSupport/decorators/property.js";
import "../../core/has.js";
import "../../core/Logger.js";
import { enumeration as i } from "../../core/accessorSupport/decorators/enumeration.js";
import { subclass as l } from "../../core/accessorSupport/decorators/subclass.js";
import p from "./Callout3D.js";
import c from "./LineCallout3DBorder.js";
import { colorAndTransparencyProperty as m, screenSizeProperty as a } from "../support/materialUtils.js";
var n;
let u = n = class extends p {
  constructor(o) {
    super(o), this.type = "line", this.color = new r([0, 0, 0, 1]), this.size = s(1), this.border = null;
  }
  get visible() {
    return this.size > 0 && null != this.color && this.color.a > 0;
  }
  clone() {
    return new n({
      color: t(this.color),
      size: this.size,
      border: t(this.border)
    });
  }
};
o([i({
  line: "line"
})], u.prototype, "type", void 0), o([e(m)], u.prototype, "color", void 0), o([e(a)], u.prototype, "size", void 0), o([e({
  type: c,
  json: {
    write: !0
  }
})], u.prototype, "border", void 0), o([e({
  readOnly: !0
})], u.prototype, "visible", null), u = n = o([l("esri.symbols.callouts.LineCallout3D")], u);
const d = u;
export { d as default };