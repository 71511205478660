/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as t } from "../chunks/tslib.es6.js";
import { clone as s } from "../core/lang.js";
import { toPt as o } from "../core/screenUtils.js";
import { property as e } from "../core/accessorSupport/decorators/property.js";
import "../core/has.js";
import "../core/Logger.js";
import { enumeration as r } from "../core/accessorSupport/decorators/enumeration.js";
import { subclass as i } from "../core/accessorSupport/decorators/subclass.js";
import l from "./FillSymbol.js";
import { urlPropertyDefinition as h, sourcePropertyDefinition as p } from "./support/urlUtils.js";
var c;
let u = c = class extends l {
  constructor(...t) {
    super(...t), this.type = "picture-fill", this.url = null, this.xscale = 1, this.yscale = 1, this.width = 12, this.height = 12, this.xoffset = 0, this.yoffset = 0, this.source = null;
  }
  normalizeCtorArgs(t, s, e, r) {
    if (t && "string" != typeof t && null == t.imageData) return t;
    const i = {};
    return t && (i.url = t), s && (i.outline = s), null != e && (i.width = o(e)), null != r && (i.height = o(r)), i;
  }
  clone() {
    const t = new c({
      color: s(this.color),
      height: this.height,
      outline: s(this.outline),
      url: this.url,
      width: this.width,
      xoffset: this.xoffset,
      xscale: this.xscale,
      yoffset: this.yoffset,
      yscale: this.yscale
    });
    return t._set("source", s(this.source)), t;
  }
  hash() {
    return `${super.hash()}.${this.color?.hash()}.${this.height}.${this.url}.${this.width}.${this.xoffset}.${this.xscale}.${this.yoffset}.${this.yscale}`;
  }
};
t([r({
  esriPFS: "picture-fill"
}, {
  readOnly: !0
})], u.prototype, "type", void 0), t([e(h)], u.prototype, "url", void 0), t([e({
  type: Number,
  json: {
    write: !0
  }
})], u.prototype, "xscale", void 0), t([e({
  type: Number,
  json: {
    write: !0
  }
})], u.prototype, "yscale", void 0), t([e({
  type: Number,
  cast: o,
  json: {
    write: !0
  }
})], u.prototype, "width", void 0), t([e({
  type: Number,
  cast: o,
  json: {
    write: !0
  }
})], u.prototype, "height", void 0), t([e({
  type: Number,
  cast: o,
  json: {
    write: !0
  }
})], u.prototype, "xoffset", void 0), t([e({
  type: Number,
  cast: o,
  json: {
    write: !0
  }
})], u.prototype, "yoffset", void 0), t([e(p)], u.prototype, "source", void 0), u = c = t([i("esri.symbols.PictureFillSymbol")], u);
const a = u;
export { a as default };