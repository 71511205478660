/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import e from "./Edges3D.js";
import s from "./SketchEdges3D.js";
import o from "./SolidEdges3D.js";
const t = {
  types: {
    key: "type",
    base: e,
    typeMap: {
      solid: o,
      sketch: s
    }
  },
  json: {
    write: !0
  }
};
export { t as symbol3dEdgesProperty };