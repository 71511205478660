/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as t } from "../../chunks/tslib.es6.js";
import r from "../../core/Accessor.js";
import { property as o } from "../../core/accessorSupport/decorators/property.js";
import "../../core/has.js";
import "../../core/Logger.js";
import "../../core/RandomLCG.js";
import { subclass as s } from "../../core/accessorSupport/decorators/subclass.js";
import e from "../../portal/Portal.js";
var p;
let l = p = class extends r {
  constructor(t) {
    super(t), this.name = null, this.styleUrl = null, this.styleName = null, this.portal = null;
  }
  clone() {
    return new p({
      name: this.name,
      styleUrl: this.styleUrl,
      styleName: this.styleName,
      portal: this.portal
    });
  }
};
t([o({
  type: String
})], l.prototype, "name", void 0), t([o({
  type: String
})], l.prototype, "styleUrl", void 0), t([o({
  type: String
})], l.prototype, "styleName", void 0), t([o({
  type: e
})], l.prototype, "portal", void 0), l = p = t([s("esri.symbols.support.StyleOrigin")], l);
const i = l;
export { i as default };