/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import n from "../../Color.js";
const o = new n("white"),
  r = new n("black"),
  e = new n([255, 255, 255, 0]);
function t(n) {
  return 0 === n.r && 0 === n.g && 0 === n.b;
}
export { r as black, t as isBlack, e as transparentWhite, o as white };