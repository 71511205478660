/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as r } from "../../chunks/tslib.es6.js";
import "../../core/Logger.js";
import "../../core/has.js";
import "../../core/RandomLCG.js";
import "../../core/Error.js";
import { enumeration as o } from "../../core/accessorSupport/decorators/enumeration.js";
import { subclass as s } from "../../core/accessorSupport/decorators/subclass.js";
import e from "./Edges3D.js";
var t;
let c = t = class extends e {
  constructor(r) {
    super(r), this.type = "sketch";
  }
  clone() {
    return new t(this.cloneProperties());
  }
};
r([o({
  sketch: "sketch"
}, {
  readOnly: !0
})], c.prototype, "type", void 0), c = t = r([s("esri.symbols.edges.SketchEdges3D")], c);
const p = c;
export { p as default };