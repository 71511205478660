/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as o } from "../../chunks/tslib.es6.js";
import r from "../../Color.js";
import { JSONSupport as s } from "../../core/JSONSupport.js";
import { px2pt as t } from "../../core/screenUtils.js";
import { property as e } from "../../core/accessorSupport/decorators/property.js";
import "../../core/has.js";
import "../../core/Logger.js";
import "../../core/RandomLCG.js";
import { subclass as c } from "../../core/accessorSupport/decorators/subclass.js";
import { colorAndTransparencyProperty as i, screenSizeProperty as p } from "./materialUtils.js";
var l;
let m = l = class extends s {
  constructor(o) {
    super(o), this.color = new r([0, 0, 0, 1]), this.size = t(1);
  }
  clone() {
    const o = {
      color: null != this.color ? this.color.clone() : null,
      size: this.size
    };
    return new l(o);
  }
};
o([e(i)], m.prototype, "color", void 0), o([e(p)], m.prototype, "size", void 0), m = l = o([c("esri.symbols.support.Symbol3DIconOutline")], m);
export { m as Symbol3DIconOutline };