/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as o } from "../chunks/tslib.es6.js";
import e from "../core/Collection.js";
import { clone as r } from "../core/lang.js";
import { property as s } from "../core/accessorSupport/decorators/property.js";
import "../core/has.js";
import "../core/Logger.js";
import { enumeration as t } from "../core/accessorSupport/decorators/enumeration.js";
import { subclass as p } from "../core/accessorSupport/decorators/subclass.js";
import i from "./LineSymbol3DLayer.js";
import m from "./PathSymbol3DLayer.js";
import y from "./Symbol3D.js";
var l;
const a = e.ofType({
    base: null,
    key: "type",
    typeMap: {
      line: i,
      path: m
    }
  }),
  n = e.ofType({
    base: null,
    key: "type",
    typeMap: {
      line: i,
      path: m
    }
  });
let c = l = class extends y {
  constructor(o) {
    super(o), this.symbolLayers = new a(), this.type = "line-3d";
  }
  clone() {
    return new l({
      styleOrigin: r(this.styleOrigin),
      symbolLayers: r(this.symbolLayers),
      thumbnail: r(this.thumbnail)
    });
  }
  static fromSimpleLineSymbol(o) {
    return new l({
      symbolLayers: new e([i.fromSimpleLineSymbol(o)])
    });
  }
};
o([s({
  type: a,
  json: {
    type: n
  }
})], c.prototype, "symbolLayers", void 0), o([t({
  LineSymbol3D: "line-3d"
}, {
  readOnly: !0
})], c.prototype, "type", void 0), c = l = o([p("esri.symbols.LineSymbol3D")], c);
const b = c;
export { b as default };