/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as o } from "../chunks/tslib.es6.js";
import { property as t } from "../core/accessorSupport/decorators/property.js";
import "../core/has.js";
import "../core/Logger.js";
import "../core/RandomLCG.js";
import { enumeration as e } from "../core/accessorSupport/decorators/enumeration.js";
import { subclass as r } from "../core/accessorSupport/decorators/subclass.js";
import i from "./Symbol3DLayer.js";
import { ObjectSymbol3DLayerResource as s } from "./support/ObjectSymbol3DLayerResource.js";
import { Symbol3DAnchorPosition3D as p } from "./support/Symbol3DAnchorPosition3D.js";
import { Symbol3DMaterial as n } from "./support/Symbol3DMaterial.js";
var h;
let a = h = class extends i {
  constructor(o) {
    super(o), this.material = null, this.castShadows = !0, this.resource = null, this.type = "object", this.width = void 0, this.height = void 0, this.depth = void 0, this.anchor = void 0, this.anchorPosition = void 0, this.heading = void 0, this.tilt = void 0, this.roll = void 0;
  }
  clone() {
    return new h({
      heading: this.heading,
      tilt: this.tilt,
      roll: this.roll,
      anchor: this.anchor,
      anchorPosition: this.anchorPosition?.clone(),
      depth: this.depth,
      enabled: this.enabled,
      height: this.height,
      material: this.material?.clone() ?? null,
      castShadows: this.castShadows,
      resource: this.resource?.clone(),
      width: this.width
    });
  }
  get isPrimitive() {
    return !this.resource || "string" != typeof this.resource.href;
  }
};
o([t({
  type: n,
  json: {
    write: !0
  }
})], a.prototype, "material", void 0), o([t({
  type: Boolean,
  nonNullable: !0,
  json: {
    write: !0,
    default: !0
  }
})], a.prototype, "castShadows", void 0), o([t({
  type: s,
  json: {
    write: !0
  }
})], a.prototype, "resource", void 0), o([e({
  Object: "object"
}, {
  readOnly: !0
})], a.prototype, "type", void 0), o([t({
  type: Number,
  json: {
    write: !0
  }
})], a.prototype, "width", void 0), o([t({
  type: Number,
  json: {
    write: !0
  }
})], a.prototype, "height", void 0), o([t({
  type: Number,
  json: {
    write: !0
  }
})], a.prototype, "depth", void 0), o([e({
  center: "center",
  top: "top",
  bottom: "bottom",
  origin: "origin",
  relative: "relative"
}), t({
  json: {
    default: "origin"
  }
})], a.prototype, "anchor", void 0), o([t({
  type: p,
  json: {
    type: [Number],
    read: {
      reader: o => new p({
        x: o[0],
        y: o[1],
        z: o[2]
      })
    },
    write: {
      writer: (o, t) => {
        t.anchorPosition = [o.x, o.y, o.z];
      },
      overridePolicy() {
        return {
          enabled: "relative" === this.anchor
        };
      }
    }
  }
})], a.prototype, "anchorPosition", void 0), o([t({
  type: Number,
  json: {
    write: !0
  }
})], a.prototype, "heading", void 0), o([t({
  type: Number,
  json: {
    write: !0
  }
})], a.prototype, "tilt", void 0), o([t({
  type: Number,
  json: {
    write: !0
  }
})], a.prototype, "roll", void 0), o([t({
  readOnly: !0
})], a.prototype, "isPrimitive", null), a = h = o([r("esri.symbols.ObjectSymbol3DLayer")], a);
const c = a;
export { c as default };