/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as e } from "../chunks/tslib.es6.js";
import { JSONSupport as r } from "../core/JSONSupport.js";
import { property as o } from "../core/accessorSupport/decorators/property.js";
import "../core/has.js";
import "../core/Logger.js";
import "../core/RandomLCG.js";
import { subclass as t } from "../core/accessorSupport/decorators/subclass.js";
import { writer as s } from "../core/accessorSupport/decorators/writer.js";
let p = class extends r {
  constructor(e) {
    super(e), this.enabled = !0, this.type = null, this.ignoreDrivers = !1;
  }
  writeEnabled(e, r, o) {
    e || (r[o] = e);
  }
};
e([o({
  type: Boolean,
  json: {
    read: {
      source: "enable"
    },
    write: {
      target: "enable"
    }
  }
})], p.prototype, "enabled", void 0), e([s("enabled")], p.prototype, "writeEnabled", null), e([o({
  type: ["icon", "object", "line", "path", "fill", "water", "extrude", "text"],
  readOnly: !0
})], p.prototype, "type", void 0), p = e([t("esri.symbols.Symbol3DLayer")], p);
const a = p;
export { a as default };