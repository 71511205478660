/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as r } from "../../chunks/tslib.es6.js";
import { strict as e } from "../../core/jsonMap.js";
import { JSONSupport as o } from "../../core/JSONSupport.js";
import { isDataProtocol as t, isAbsolute as s, normalize as p } from "../../core/urlUtils.js";
import { property as c } from "../../core/accessorSupport/decorators/property.js";
import "../../core/has.js";
import "../../core/Logger.js";
import "../../core/RandomLCG.js";
import { enumeration as i } from "../../core/accessorSupport/decorators/enumeration.js";
import { reader as a } from "../../core/accessorSupport/decorators/reader.js";
import { subclass as m } from "../../core/accessorSupport/decorators/subclass.js";
import { writer as f } from "../../core/accessorSupport/decorators/writer.js";
import { f as n, t as u } from "../../chunks/persistableUrlUtils.js";
var l;
const d = e()({
  circle: "circle",
  square: "square",
  cross: "cross",
  x: "x",
  kite: "kite",
  triangle: "triangle"
});
let h = l = class extends o {
  constructor(r) {
    super(r);
  }
  readHref(r, e, o) {
    return r ? n(r, o) : e.dataURI;
  }
  writeHref(r, e, o, c) {
    r && (t(r) ? e.dataURI = r : (e.href = u(r, c), s(e.href) && (e.href = p(e.href))));
  }
  clone() {
    return new l({
      href: this.href,
      primitive: this.primitive
    });
  }
};
r([c({
  type: String,
  json: {
    write: !0,
    read: {
      source: ["href", "dataURI"]
    }
  }
})], h.prototype, "href", void 0), r([a("href")], h.prototype, "readHref", null), r([f("href", {
  href: {
    type: String
  },
  dataURI: {
    type: String
  }
})], h.prototype, "writeHref", null), r([i(d)], h.prototype, "primitive", void 0), h = l = r([m("esri.symbols.support.IconSymbol3DLayerResource")], h);
const j = "circle";
export { h as IconSymbol3DLayerResource, j as defaultPrimitive };