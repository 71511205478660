/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as o } from "../../chunks/tslib.es6.js";
import r from "../../Color.js";
import { JSONSupport as s } from "../../core/JSONSupport.js";
import { clone as t } from "../../core/lang.js";
import { property as e } from "../../core/accessorSupport/decorators/property.js";
import "../../core/has.js";
import "../../core/Logger.js";
import { subclass as c } from "../../core/accessorSupport/decorators/subclass.js";
import { colorAndTransparencyProperty as p } from "../support/materialUtils.js";
var l;
let i = l = class extends s {
  constructor(o) {
    super(o), this.color = new r("white");
  }
  clone() {
    return new l({
      color: t(this.color)
    });
  }
};
o([e(p)], i.prototype, "color", void 0), i = l = o([c("esri.symbols.callouts.LineCallout3DBorder")], i);
const m = i;
export { m as default };