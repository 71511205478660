/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as o } from "../../chunks/tslib.es6.js";
import "../../core/Logger.js";
import "../../core/has.js";
import "../../core/RandomLCG.js";
import "../../core/Error.js";
import { enumeration as r } from "../../core/accessorSupport/decorators/enumeration.js";
import { subclass as s } from "../../core/accessorSupport/decorators/subclass.js";
import { Symbol3DMaterial as t } from "./Symbol3DMaterial.js";
var e;
let l = e = class extends t {
  constructor(o) {
    super(o), this.colorMixMode = null;
  }
  clone() {
    const o = {
      color: null != this.color ? this.color.clone() : null,
      colorMixMode: this.colorMixMode
    };
    return new e(o);
  }
};
o([r({
  multiply: "multiply",
  replace: "replace",
  tint: "tint"
})], l.prototype, "colorMixMode", void 0), l = e = o([s("esri.symbols.support.Symbol3DFillMaterial")], l);
export { l as Symbol3DFillMaterial };